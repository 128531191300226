var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      ref: "radioButtonContainer"
    },
    [
      !_vm.bold
        ? _c("p", { staticClass: "text-body-1 my-0" }, [
            _vm._v(" " + _vm._s(_vm.questionText(_vm.index)) + " ")
          ])
        : _c("p", { staticClass: "text-body-1 my-0" }, [
            _c("b", [_vm._v(_vm._s(_vm.questionText(_vm.index)))])
          ]),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "10", offset: "1" } },
        [
          _c(
            "v-radio-group",
            {
              attrs: { row: "", rules: _vm.rules },
              on: {
                change: function($event) {
                  return _vm.emitToParent(_vm.selected)
                }
              },
              model: {
                value: _vm.selectedAnswer,
                callback: function($$v) {
                  _vm.selectedAnswer = $$v
                },
                expression: "selectedAnswer"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                _vm._l(_vm.options.length, function(i) {
                  return _c(
                    "v-col",
                    {
                      key: i,
                      staticClass: "pt-0",
                      attrs: {
                        cols: "12",
                        sm: "12",
                        md: _vm.forceVerticalOptions ? 12 : 6,
                        lg: _vm.forceVerticalOptions ? 12 : _vm.largeColumnSize
                      }
                    },
                    [
                      _c("v-radio", {
                        key: _vm.options[i - 1].value,
                        class: _vm.grey
                          ? "text-body-2 greyText"
                          : "text-body-2",
                        attrs: {
                          label: _vm.$t(_vm.options[i - 1].label),
                          value: _vm.options[i - 1].value
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
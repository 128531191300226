<template>
  <div class="mb-6">
    <p>{{ index + 1 + ". " + question }}</p>
    <v-text-field
      v-if="!textArea"
      :name="name"
      v-model="text"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :rules="rules"
      ref="text"
    />
    <v-textarea
      v-else
      :name="name"
      v-model="text"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      ref="text"
      :rules="rules"
    />
  </div>
</template>

<script>
export default {
  name: "TextQuestion",
  props: {
    name: String,
    placeholder: String,
    textArea: {
      type: Boolean,
      default: false
    },
    question: String,
    value: String,
    index: Number
  },
  data() {
    return {
      text: this.value ?? ""
    };
  },
  computed: {
    rules() {
      return [v => v.length > 0 || this.$t("form.textQuestion.mustAnswer")];
    }
  }
};
</script>

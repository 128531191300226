var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ]
    },
    [
      _c("p", { staticClass: "text-body-1" }, [
        _vm._v(" " + _vm._s(_vm.questionText(_vm.index)) + " ")
      ]),
      _c(
        "v-col",
        { staticClass: "my-3", attrs: { cols: "10", offset: "1" } },
        [
          _c(
            "v-row",
            {
              attrs: {
                justify: _vm.$vuetify.breakpoint.smAndDown ? "center" : "start"
              }
            },
            [
              _vm._l(_vm.options, function(option) {
                return _c(
                  "v-col",
                  {
                    key: option.value,
                    staticClass: "pt-0",
                    class: _vm.$vuetify.breakpoint.smAndDown ? "ma-3" : "",
                    attrs: { cols: "12", sm: "5", md: "4" }
                  },
                  [
                    _c("v-hover", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-3",
                                    class:
                                      option.value === _vm.value ? "zoom" : "",
                                    attrs: {
                                      elevation:
                                        hover || option.value === _vm.value
                                          ? 10
                                          : 2,
                                      rounded: "lg",
                                      height: "100%"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("input", option.value)
                                      }
                                    }
                                  },
                                  [
                                    option.value === _vm.value
                                      ? _c("v-overlay", {
                                          staticClass: "rounded-lg",
                                          attrs: {
                                            absolute: "",
                                            color: "primary",
                                            opacity: 0.2
                                          }
                                        })
                                      : _vm._e(),
                                    _c("v-img", {
                                      attrs: {
                                        src: require("@/assets/images/QuestionIcons/" +
                                          option.image),
                                        height: "75px",
                                        contain: ""
                                      }
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-body-2 text-center mb-0 mt-2"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t(option.label)) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  }
                ],
                attrs: { rules: _vm.rules },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              }),
              _c("v-scroll-y-transition", [
                _vm.showErrorMessage
                  ? _c("p", { staticClass: "error--text ml-3" }, [
                      _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                    ])
                  : _vm._e()
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
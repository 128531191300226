<template>
  <div>
    <p class="text-body-1">{{ index + 1 + ". " + question }}</p>
    <v-row>
      <v-col cols="2" class="pb-0" offset="1">
        <p class="text-body-1 mb-0">{{ $t("form.bmi.height") }}</p>
      </v-col>
      <v-col cols="2" class="pb-0" :offset="showingHeightMetric ? 0 : 2">
        <p class="text-body-1 mb-0">{{ $t("form.bmi.weight") }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" v-if="showingHeightMetric" offset="1">
        <v-text-field
          id="cmInput"
          class="text-body-2 number-input"
          v-model="metricEntry.height"
          :rules="heightRules"
          @input="updateUnits()"
          label="cm"
        />
      </v-col>
      <v-col cols="2" v-if="!showingHeightMetric" offset="1">
        <v-text-field
          id="ftInput"
          class="text-body-2 number-input"
          v-model="imperialEntry.ft"
          :rules="heightRules"
          @input="updateUnits()"
          :label="$t('form.bmi.feetAbbreviated')"
        />
      </v-col>
      <v-col cols="2" v-if="!showingHeightMetric">
        <v-text-field
          id="inInput"
          class="text-body-1 number-input"
          v-model="imperialEntry.in"
          :rules="heightRules"
          @input="updateUnits()"
          :label="$t('form.bmi.inchesAbbreviated')"
        />
      </v-col>
      <v-col cols="2" v-if="showingWeightMetric">
        <v-text-field
          id="kgInput"
          class="text-body-2 number-input"
          v-model="metricEntry.weight"
          :rules="weightRules"
          @input="updateUnits()"
          label="kg"
        />
      </v-col>
      <v-col cols="2" v-if="!showingWeightMetric">
        <v-text-field
          id="stInput"
          class="text-body-2 number-input"
          v-model="imperialEntry.st"
          :rules="weightRules"
          @input="updateUnits()"
          :label="$t('form.bmi.stoneAbbreviated')"
        />
      </v-col>
      <v-col cols="2" v-if="!showingWeightMetric">
        <v-text-field
          id="lbInput"
          class="text-body-2 number-input"
          v-model="imperialEntry.lb"
          :rules="weightRules"
          @input="updateUnits()"
          :label="$t('form.bmi.poundsAbbreviated')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" align-self="start" class="pt-0" offset="1">
        <v-btn
          outlined
          x-small
          id="heightUnitsButton"
          color="primary"
          @click="changeUnits(true)"
          >{{
            showingHeightMetric
              ? $t("form.bmi.feetAbbreviated") +
                " / " +
                $t("form.bmi.inchesAbbreviated")
              : "cm"
          }}</v-btn
        >
      </v-col>
      <v-col
        cols="2"
        align-self="start"
        class="pt-0"
        :offset="showingHeightMetric ? 0 : 2"
      >
        <v-btn
          outlined
          x-small
          id="weightUnitsButton"
          color="primary"
          @click="changeUnits(false)"
          >{{
            showingWeightMetric
              ? $t("form.bmi.stoneAbbreviated") +
                " / " +
                $t("form.bmi.poundsAbbreviated")
              : "kg"
          }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" offset="1">
        <p class="text-body-1 ml-1">
          {{ $t("form.bmi.definition") + " " + bmi }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BMIQuestion",
  props: {
    name: String,
    question: String,
    value: Object,
    index: Number,
    optional: Boolean
  },
  data() {
    return {
      metricEntry: this.value || { height: "", weight: "", bmi: "" },
      imperialEntry: { ft: "", in: "", st: "", lb: "" },
      showingHeightMetric: true,
      showingWeightMetric: true,
      multipliers: {
        inchToFeet: 12,
        poundToStone: 14,
        inchToCentimeter: 2.54,
        poundToKilogram: 0.454
      },
      rules: {
        inputRequired(m) {
          return v => !!v || m;
        },
        mustBeANumber(m) {
          return v => !isNaN(v) || m;
        }
      }
    };
  },
  mounted() {
    this.showingHeightMetric = this.metricSystem;
    this.showingWeightMetric = this.metricSystem;
  },
  computed: {
    ...mapGetters(["metricSystem"]),
    bmi() {
      var weight = parseInt(this.metricEntry.weight);
      var height = parseInt(this.metricEntry.height) / 100;
      let bmi = weight / (height * height);
      bmi = isNaN(bmi) || !isFinite(bmi) ? 0 : bmi;
      return Math.round(bmi * 10) / 10;
    },
    heightRules() {
      return this.optional
        ? [this.rules.mustBeANumber(this.$t("form.bmi.mustBeANumber"))]
        : [
            this.rules.mustBeANumber(this.$t("form.bmi.mustBeANumber")),
            this.rules.inputRequired(this.$t("form.bmi.enterHeight"))
          ];
    },
    weightRules() {
      return this.optional
        ? [this.rules.mustBeANumber(this.$t("form.bmi.mustBeANumber"))]
        : [
            this.rules.mustBeANumber(this.$t("form.bmi.mustBeANumber")),
            this.rules.inputRequired(this.$t("form.bmi.enterWeight"))
          ];
    }
  },
  methods: {
    convertHeightToImperial() {
      let totalInches =
        parseInt(this.metricEntry.height) / this.multipliers.inchToCentimeter;
      let feet = Math.floor(totalInches / this.multipliers.inchToFeet);
      let remainderInches = totalInches - feet * this.multipliers.inchToFeet;
      this.imperialEntry.ft = isNaN(feet) ? "" : feet;
      this.imperialEntry.in = isNaN(remainderInches)
        ? ""
        : Math.round(remainderInches);
    },
    convertHeightToMetric() {
      if (
        !isNaN(parseInt(this.imperialEntry.ft)) &&
        isNaN(parseInt(this.imperialEntry.in))
      ) {
        this.imperialEntry.in = 0;
      } else if (
        !isNaN(parseInt(this.imperialEntry.in)) &&
        isNaN(parseInt(this.imperialEntry.ft))
      ) {
        this.imperialEntry.ft = 0;
      }

      let centimeters =
        (parseInt(this.imperialEntry.ft) * this.multipliers.inchToFeet +
          parseInt(this.imperialEntry.in)) *
        this.multipliers.inchToCentimeter;
      this.metricEntry.height = isNaN(centimeters)
        ? ""
        : Math.round(centimeters);
    },
    convertWeightToImperial() {
      let totalPounds =
        parseInt(this.metricEntry.weight) / this.multipliers.poundToKilogram;
      let stone = Math.floor(totalPounds / this.multipliers.poundToStone);
      let remainderPounds = totalPounds - stone * this.multipliers.poundToStone;
      this.imperialEntry.st = isNaN(stone) ? "" : stone;
      this.imperialEntry.lb = isNaN(remainderPounds)
        ? ""
        : Math.round(remainderPounds);
    },
    convertWeightToMetric() {
      if (
        !isNaN(parseInt(this.imperialEntry.st)) &&
        isNaN(parseInt(this.imperialEntry.lb))
      ) {
        this.imperialEntry.lb = 0;
      } else if (
        !isNaN(parseInt(this.imperialEntry.lb)) &&
        isNaN(parseInt(this.imperialEntry.st))
      ) {
        this.imperialEntry.st = 0;
      }
      let kilograms =
        (parseInt(this.imperialEntry.st) * this.multipliers.poundToStone +
          parseInt(this.imperialEntry.lb)) *
        this.multipliers.poundToKilogram;
      this.metricEntry.weight = isNaN(kilograms) ? "" : Math.round(kilograms);
    },
    updateUnits() {
      if (this.showingHeightMetric) {
        this.convertHeightToImperial();
      } else {
        this.convertHeightToMetric();
      }
      if (this.showingWeightMetric) {
        this.convertWeightToImperial();
      } else {
        this.convertWeightToMetric();
      }
      this.metricEntry.bmi = this.bmi;
      this.$emit("input", this.metricEntry);
    },
    changeUnits(convertHeight) {
      this.updateUnits();
      this.showingHeightMetric = convertHeight
        ? !this.showingHeightMetric
        : this.showingHeightMetric;
      this.showingWeightMetric = convertHeight
        ? this.showingWeightMetric
        : !this.showingWeightMetric;
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.metricEntry = newValue;
      }
    }
  }
};
</script>

<style scoped>
.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>

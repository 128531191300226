<template>
  <div class="pb-5">
    <p class="mb-10 text-body-1">{{ index + 1 + ". " + $t(question) }}</p>
    <v-col
      :cols="$vuetify.breakpoint.smAndDown ? '12' : '10'"
      :offset="$vuetify.breakpoint.smAndDown ? '' : '1'"
    >
      <v-slider
        :name="name"
        v-model="sliderValue"
        @input="emitToParent($event)"
        @mousedown="handleChanged"
        :max="max"
        :min="optional && !isUsed ? null : min"
        thumb-label="always"
        :tick-labels="translatedLabels"
        :color="currentColor"
        track-color="gray"
        :ticks="true"
        tick-size="3"
        hide-details
      >
        <template
          v-if="icons && (!this.optional || this.isUsed)"
          v-slot:thumb-label="prop"
        >
          <v-img
            max-width="20px"
            :src="
              require('@/assets/images/QuestionIcons/' +
                getIcon(prop.value) +
                '.png')
            "
          />
        </template>

        <template v-else v-slot:thumb-label="prop">{{
          getThumbLabel(prop.value)
        }}</template>

        <template v-slot:prepend>
          <v-text-field
            v-if="showTextBox"
            v-model="sliderValue"
            :max="max"
            :min="min"
            :disabled="optional && !isUsed"
            class="mt-0 pt-0"
            type="number"
            style="width: 50px"
            hide-details
          ></v-text-field>
        </template>

        <template v-slot:append> </template>
        <template v-slot:label="labelText" class="text-caption">{{
          labelText
        }}</template>
      </v-slider>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "SliderQuestion",
  props: {
    name: String,
    question: String,
    max: Number,
    min: Number,
    value: Number,
    optional: Boolean,
    index: Number,
    appendLessMore: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array
    },
    icons: Array,
    showActiveLabelOnSmallerScreens: Boolean,
    color: String,
    colorProgression: Array,
    showTextBox: Boolean
  },
  data() {
    return {
      sliderValue: this.optional ? null : this.value,
      isUsed: false
    };
  },
  computed: {
    translatedLabels() {
      if (!this.labels || (this.optional && !this.isUsed)) {
        return [];
      }
      return this.labels.map(i => this.$t(i));
    },
    currentColor() {
      if (this.color) {
        return this.color;
      }
      if (this.colorProgression) {
        let index = this.value - this.min;
        return this.colorProgression[index];
      }
      return "secondary";
    }
  },
  mounted() {
    if (this.value === this.min && !this.optional) {
      this.emitToParent(this.min);
    }
  },
  methods: {
    getLabelValue(value) {
      let index = value - this.min;
      return this.$t(this.labels[index]);
    },
    handleChanged(value) {
      if (
        this.optional &&
        !this.isUsed &&
        (isNaN(this.value) || this.value === null)
      ) {
        this.emitToParent(this.min);
        this.isUsed = true;
      }
    },
    getIcon(selected) {
      if (isNaN(selected) || selected === null) {
        return this.icons[0];
      }
      let index = selected - this.min;
      return this.icons[index];
    },
    emitToParent(value) {
      if (isNaN(value) || value === null) {
        return;
      }
      this.$emit("input", value);
    },
    getThumbLabel(selected) {
      let label = selected;
      if (isNaN(label)) {
        return this.$t("form.notApplicable");
      }
      if (label === this.min && label > 0 && this.appendLessMore) {
        return "< " + label;
      }
      if (label === this.max && this.appendLessMore) {
        return label + "+";
      }
      return label;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-slider__tick-label {
  font-size: 10px;
  color: var(--v-vitrueDarkGrey-base);
  max-width: 60px;
  white-space: pre-line;
  transform: translateX(-50%);
  line-height: 1;
  text-align: center;
}
</style>

import { render, staticRenderFns } from "./CheckBoxListQuestion.vue?vue&type=template&id=30c83992&"
import script from "./CheckBoxListQuestion.vue?vue&type=script&lang=js&"
export * from "./CheckBoxListQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VCol})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30c83992')) {
      api.createRecord('30c83992', component.options)
    } else {
      api.reload('30c83992', component.options)
    }
    module.hot.accept("./CheckBoxListQuestion.vue?vue&type=template&id=30c83992&", function () {
      api.rerender('30c83992', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/CheckBoxListQuestion.vue"
export default component.exports
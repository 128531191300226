var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _c("p", { staticClass: "mb-10 text-body-1" }, [
        _vm._v(_vm._s(_vm.index + 1 + ". " + _vm.$t(_vm.question)))
      ]),
      _c(
        "v-col",
        {
          attrs: {
            cols: _vm.$vuetify.breakpoint.smAndDown ? "12" : "10",
            offset: _vm.$vuetify.breakpoint.smAndDown ? "" : "1"
          }
        },
        [
          _c("v-slider", {
            attrs: {
              name: _vm.name,
              max: _vm.max,
              min: _vm.optional && !_vm.isUsed ? null : _vm.min,
              "thumb-label": "always",
              "tick-labels": _vm.translatedLabels,
              color: _vm.currentColor,
              "track-color": "gray",
              ticks: true,
              "tick-size": "3",
              "hide-details": ""
            },
            on: {
              input: function($event) {
                return _vm.emitToParent($event)
              },
              mousedown: _vm.handleChanged
            },
            scopedSlots: _vm._u(
              [
                _vm.icons && (!this.optional || this.isUsed)
                  ? {
                      key: "thumb-label",
                      fn: function(prop) {
                        return [
                          _c("v-img", {
                            attrs: {
                              "max-width": "20px",
                              src: require("@/assets/images/QuestionIcons/" +
                                _vm.getIcon(prop.value) +
                                ".png")
                            }
                          })
                        ]
                      }
                    }
                  : {
                      key: "thumb-label",
                      fn: function(prop) {
                        return [_vm._v(_vm._s(_vm.getThumbLabel(prop.value)))]
                      }
                    },
                {
                  key: "prepend",
                  fn: function() {
                    return [
                      _vm.showTextBox
                        ? _c("v-text-field", {
                            staticClass: "mt-0 pt-0",
                            staticStyle: { width: "50px" },
                            attrs: {
                              max: _vm.max,
                              min: _vm.min,
                              disabled: _vm.optional && !_vm.isUsed,
                              type: "number",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.sliderValue,
                              callback: function($$v) {
                                _vm.sliderValue = $$v
                              },
                              expression: "sliderValue"
                            }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "append",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                },
                {
                  key: "label",
                  fn: function(labelText) {
                    return [_vm._v(_vm._s(labelText))]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.sliderValue,
              callback: function($$v) {
                _vm.sliderValue = $$v
              },
              expression: "sliderValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
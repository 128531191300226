import { render, staticRenderFns } from "./TextQuestion.vue?vue&type=template&id=02af1e44&"
import script from "./TextQuestion.vue?vue&type=script&lang=js&"
export * from "./TextQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02af1e44')) {
      api.createRecord('02af1e44', component.options)
    } else {
      api.reload('02af1e44', component.options)
    }
    module.hot.accept("./TextQuestion.vue?vue&type=template&id=02af1e44&", function () {
      api.rerender('02af1e44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/form/TextQuestion.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-6" },
    [
      _c("p", [_vm._v(_vm._s(_vm.index + 1 + ". " + _vm.question))]),
      !_vm.textArea
        ? _c("v-text-field", {
            ref: "text",
            attrs: {
              name: _vm.name,
              placeholder: _vm.placeholder,
              rules: _vm.rules
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        : _c("v-textarea", {
            ref: "text",
            attrs: {
              name: _vm.name,
              placeholder: _vm.placeholder,
              rules: _vm.rules
            },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event)
              }
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
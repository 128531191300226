var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "pb-1"
    },
    [
      !_vm.bold
        ? _c("p", { staticClass: "text-body-1" }, [
            _vm._v(
              " " + _vm._s(_vm.index + 1 + ". " + _vm.$t(_vm.question)) + " "
            )
          ])
        : _c("p", { staticClass: "text-body-1" }, [
            _c("b", [
              _vm._v(_vm._s(_vm.index + 1 + ". " + _vm.$t(_vm.question)))
            ])
          ]),
      _c(
        "v-col",
        { staticClass: "pt-0", attrs: { cols: "10", offset: "1" } },
        [
          _vm._l(_vm.options.length, function(n) {
            return _c(
              "div",
              { key: n, staticClass: "text-body-2" },
              [
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t(_vm.options[n - 1].label),
                    value: _vm.options[n - 1].value,
                    rules: [
                      function() {
                        return (
                          _vm.selected.length > 0 ||
                          _vm.$t("form.checkboxList.selectOption")
                        )
                      }
                    ],
                    "hide-details": n !== _vm.options.length
                  },
                  on: {
                    change: function($event) {
                      return _vm.emitToParent(_vm.selected)
                    }
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                })
              ],
              1
            )
          }),
          _vm.showNonePrompt
            ? _c("p", { staticClass: "caption red--text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("form.checkboxList.deselectInstruction", {
                        "0": _vm.$t(_vm.options[_vm.options.length - 1].label)
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-if="show">
    <p class="text-body-1 py-0">{{ index + 1 + ". " + $t(question) }}</p>
    <v-col cols="10" offset="1" class="pt-0">
      <v-radio-group
        class="text-body-2"
        v-model="selected"
        :rules="[v => !!v || $t('form.radioButton.mustAnswer')]"
        @change="emitToParent(selected)"
      >
        <v-radio
          :class="grey ? 'text-body-2 greyText' : 'text-body-2'"
          v-for="n in options.length"
          :key="n"
          :label="$t(options[n - 1].label)"
          :value="options[n - 1].value"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "VerticalRadioButtonQuestion",
  props: {
    name: String,
    question: String,
    options: Array,
    value: Number,
    index: Number,
    show: {
      default: true,
      type: Boolean
    },
    grey: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    }
  },
  data() {
    return { selected: this.value };
  }
};
</script>

<style scoped>
.greyText /deep/ label {
  color: lightgrey;
}
</style>

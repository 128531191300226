<template>
  <div ref="radioButtonContainer" v-show="show">
    <p v-if="!bold" class="text-body-1 my-0">
      {{ questionText(index) }}
    </p>
    <p v-else class="text-body-1 my-0">
      <b>{{ questionText(index) }}</b>
    </p>
    <v-col cols="10" offset="1" class="pt-0">
      <v-radio-group
        row
        v-model="selectedAnswer"
        @change="emitToParent(selected)"
        :rules="rules"
      >
        <v-row align="center">
          <v-col
            class="pt-0"
            cols="12"
            sm="12"
            :md="forceVerticalOptions ? 12 : 6"
            :lg="forceVerticalOptions ? 12 : largeColumnSize"
            v-for="i in options.length"
            :key="i"
          >
            <v-radio
              :class="grey ? 'text-body-2 greyText' : 'text-body-2'"
              :key="options[i - 1].value"
              :label="$t(options[i - 1].label)"
              :value="options[i - 1].value"
            ></v-radio>
          </v-col>
        </v-row>
      </v-radio-group>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "RadioButtonQuestion",
  props: {
    name: String,
    question: String,
    options: Array,
    value: Number,
    index: Number,
    totalQuestionsOnPage: Number,
    show: {
      default: true,
      type: Boolean
    },
    optional: Boolean,
    bold: {
      default: false,
      type: Boolean
    },
    grey: {
      default: false,
      type: Boolean
    },
    forceVerticalOptions: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    emitToParent(value) {
      this.$emit("input", value);
    },
    questionText(index) {
      if (this.totalQuestionsOnPage === 1) {
        return this.$t(this.question);
      }
      return index + 1 + ". " + this.$t(this.question);
    }
  },
  data() {
    return { selected: null };
  },
  computed: {
    selectedAnswer: {
      get() {
        return this.value;
      },
      set(selectedAnswer) {
        this.selected = selectedAnswer;
      }
    },
    rules() {
      return this.optional
        ? []
        : [v => !!v || this.$t("form.radioButton.mustAnswer")];
    },
    largeColumnSize() {
      if (window.devicePixelRatio > 1 && this.options.length > 3) {
        return 6;
      }
      return 3;
    }
  }
};
</script>

<style scoped>
.greyText /deep/ label {
  color: lightgrey;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c("p", { staticClass: "text-body-1 py-0" }, [
            _vm._v(_vm._s(_vm.index + 1 + ". " + _vm.$t(_vm.question)))
          ]),
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "10", offset: "1" } },
            [
              _c(
                "v-radio-group",
                {
                  staticClass: "text-body-2",
                  attrs: {
                    rules: [
                      function(v) {
                        return !!v || _vm.$t("form.radioButton.mustAnswer")
                      }
                    ]
                  },
                  on: {
                    change: function($event) {
                      return _vm.emitToParent(_vm.selected)
                    }
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                _vm._l(_vm.options.length, function(n) {
                  return _c("v-radio", {
                    key: n,
                    class: _vm.grey ? "text-body-2 greyText" : "text-body-2",
                    attrs: {
                      label: _vm.$t(_vm.options[n - 1].label),
                      value: _vm.options[n - 1].value
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
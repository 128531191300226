<template>
  <div style="width: 100%">
    <div
      v-for="(question, index) in visibleQuestions"
      :key="question.name"
      :class="isGrey(index) ? 'grey--text text--lighten-1' : ''"
    >
      <v-tooltip right max-width="250px">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="text-caption font-weight-light font-italic"
            v-if="question.optional"
            style="display: inline-block"
          >
            {{ $t("form.generator.optionalTooltip") }}
            <v-icon x-small right color="grey lighten-1">fa-info-circle</v-icon>
          </div>
        </template>
        <span>{{ $t(question.optionalText) }}</span>
      </v-tooltip>

      <div
        v-if="!question.optional && question.infoText"
        class="text-caption font-weight-light font-italic"
        style="display: inline-block"
      >
        {{ $t(question.infoText) }}
      </div>

      <component
        :key="question.name"
        :is="question.fieldType"
        @input="save(question.fieldType)"
        v-bind="question"
        :question="getFormQuestion(index)"
        v-model="results[question.name]"
        :index="index"
        :optional="question.optional"
        :grey="isGrey(index)"
        :totalQuestionsOnPage="visibleQuestions.length"
        :valueCanBeZero="question.valueCanBeZero"
      />
    </div>
  </div>
</template>

<script>
import RadioButtonQuestion from "./RadioButtonQuestion";
import CardSelectQuestion from "./CardSelectQuestion";
import VerticalRadioButtonQuestion from "./VerticalRadioButtonQuestion";
import TextQuestion from "./TextQuestion";
import BMIQuestion from "./BMIQuestion";
import SliderQuestion from "./SliderQuestion";
import PainLevelQuestion from "./PainLevelQuestion";
import CheckBoxListQuestion from "./CheckBoxListQuestion";
import painAreaDescriptions from "@/components/painareas/PainAreasMale.json";
import criteriaChecker from "@/components/common/criteria-checker.js";

export default {
  name: "FormGenerator",
  components: {
    RadioButtonQuestion,
    TextQuestion,
    BMIQuestion,
    SliderQuestion,
    PainLevelQuestion,
    CheckBoxListQuestion,
    VerticalRadioButtonQuestion,
    CardSelectQuestion
  },
  props: {
    questions: Array,
    value: Object,
    fullResults: Object,
    popupMessage: String,
    finalQuestionEmphasis: {
      type: Boolean,
      default: false
    },
    answersToKeep: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      results: this.value ?? {},
      formQuestions: this.questions
    };
  },
  watch: {
    value: {
      handler() {
        this.results = this.value;
      },
      deep: true
    }
  },
  computed: {
    visibleQuestions() {
      return this.formQuestions.filter(x => x.show || x.show === undefined);
    }
  },
  created() {
    this.updateQuestionVisibility();
  },
  methods: {
    save(questionType) {
      this.updateQuestionVisibility();
      this.$emit("questionType", questionType);
      this.$emit("input", this.results);
    },
    updateQuestionVisibility() {
      let localQuestions = [...this.formQuestions];
      localQuestions
        .filter(question => question.showOn !== undefined)
        .forEach(question => {
          let results = this.results;
          if (question.addResults) {
            results = {
              ...this.results,
              ...this.fullResults[question.addResults]
            };
          }
          question.show = criteriaChecker.areCriteriaMet(
            results,
            question.showOn
          );
          if (!question.show && !this.answersToKeep.includes(question.name)) {
            this.$delete(this.results, question.name);
          }
        });
      this.formQuestions = [...localQuestions];
    },
    getFormQuestion(index) {
      let text = this.$t(this.visibleQuestions[index].question);
      if (text.includes("*painareas*")) {
        text = text.replace("*painareas*", this.getPainAreas());
      }
      if (text.includes("*leftright*")) {
        text = text.replace("*leftright*", [
          this.getPainAreas().includes("left")
            ? this.$t("form.generator.painAreaLeft")
            : this.$t("form.generator.painAreaRight")
        ]);
      }
      return text;
    },
    getPainAreas() {
      let painAreas = Object.keys(this.fullResults.pain.areas);
      let painAreasAsList = "";
      painAreas.forEach((area, index) => {
        painAreasAsList += this.$t(
          painAreaDescriptions[area].name
        ).toLowerCase();
        if (index != painAreas.length - 1) {
          painAreasAsList +=
            index === painAreas.length - 2
              ? " " + this.$t("form.generator.connector") + " "
              : ", ";
        }
      });
      return painAreasAsList;
    },
    isGrey(index) {
      return (
        this.finalQuestionEmphasis && index !== this.visibleQuestions.length - 1
      );
    }
  }
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    _vm._l(_vm.visibleQuestions, function(question, index) {
      return _c(
        "div",
        {
          key: question.name,
          class: _vm.isGrey(index) ? "grey--text text--lighten-1" : ""
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { right: "", "max-width": "250px" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        question.optional
                          ? _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "text-caption font-weight-light font-italic",
                                    staticStyle: { display: "inline-block" }
                                  },
                                  "div",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("form.generator.optionalTooltip")
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-small": "",
                                      right: "",
                                      color: "grey lighten-1"
                                    }
                                  },
                                  [_vm._v("fa-info-circle")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t(question.optionalText)))])]
          ),
          !question.optional && question.infoText
            ? _c(
                "div",
                {
                  staticClass: "text-caption font-weight-light font-italic",
                  staticStyle: { display: "inline-block" }
                },
                [_vm._v(" " + _vm._s(_vm.$t(question.infoText)) + " ")]
              )
            : _vm._e(),
          _c(
            question.fieldType,
            _vm._b(
              {
                key: question.name,
                tag: "component",
                attrs: {
                  question: _vm.getFormQuestion(index),
                  index: index,
                  optional: question.optional,
                  grey: _vm.isGrey(index),
                  totalQuestionsOnPage: _vm.visibleQuestions.length,
                  valueCanBeZero: question.valueCanBeZero
                },
                on: {
                  input: function($event) {
                    return _vm.save(question.fieldType)
                  }
                },
                model: {
                  value: _vm.results[question.name],
                  callback: function($$v) {
                    _vm.$set(_vm.results, question.name, $$v)
                  },
                  expression: "results[question.name]"
                }
              },
              "component",
              question,
              false
            )
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-body-1" }, [
        _vm._v(_vm._s(_vm.index + 1 + ". " + _vm.question))
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "2", offset: "1" } },
            [
              _c("p", { staticClass: "text-body-1 mb-0" }, [
                _vm._v(_vm._s(_vm.$t("form.bmi.height")))
              ])
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: "2", offset: _vm.showingHeightMetric ? 0 : 2 }
            },
            [
              _c("p", { staticClass: "text-body-1 mb-0" }, [
                _vm._v(_vm._s(_vm.$t("form.bmi.weight")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.showingHeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2", offset: "1" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-2 number-input",
                    attrs: {
                      id: "cmInput",
                      rules: _vm.heightRules,
                      label: "cm"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.metricEntry.height,
                      callback: function($$v) {
                        _vm.$set(_vm.metricEntry, "height", $$v)
                      },
                      expression: "metricEntry.height"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.showingHeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2", offset: "1" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-2 number-input",
                    attrs: {
                      id: "ftInput",
                      rules: _vm.heightRules,
                      label: _vm.$t("form.bmi.feetAbbreviated")
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.imperialEntry.ft,
                      callback: function($$v) {
                        _vm.$set(_vm.imperialEntry, "ft", $$v)
                      },
                      expression: "imperialEntry.ft"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.showingHeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-1 number-input",
                    attrs: {
                      id: "inInput",
                      rules: _vm.heightRules,
                      label: _vm.$t("form.bmi.inchesAbbreviated")
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.imperialEntry.in,
                      callback: function($$v) {
                        _vm.$set(_vm.imperialEntry, "in", $$v)
                      },
                      expression: "imperialEntry.in"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showingWeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-2 number-input",
                    attrs: {
                      id: "kgInput",
                      rules: _vm.weightRules,
                      label: "kg"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.metricEntry.weight,
                      callback: function($$v) {
                        _vm.$set(_vm.metricEntry, "weight", $$v)
                      },
                      expression: "metricEntry.weight"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.showingWeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-2 number-input",
                    attrs: {
                      id: "stInput",
                      rules: _vm.weightRules,
                      label: _vm.$t("form.bmi.stoneAbbreviated")
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.imperialEntry.st,
                      callback: function($$v) {
                        _vm.$set(_vm.imperialEntry, "st", $$v)
                      },
                      expression: "imperialEntry.st"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.showingWeightMetric
            ? _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    staticClass: "text-body-2 number-input",
                    attrs: {
                      id: "lbInput",
                      rules: _vm.weightRules,
                      label: _vm.$t("form.bmi.poundsAbbreviated")
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateUnits()
                      }
                    },
                    model: {
                      value: _vm.imperialEntry.lb,
                      callback: function($$v) {
                        _vm.$set(_vm.imperialEntry, "lb", $$v)
                      },
                      expression: "imperialEntry.lb"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: { cols: "2", "align-self": "start", offset: "1" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    "x-small": "",
                    id: "heightUnitsButton",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeUnits(true)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.showingHeightMetric
                        ? _vm.$t("form.bmi.feetAbbreviated") +
                            " / " +
                            _vm.$t("form.bmi.inchesAbbreviated")
                        : "cm"
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: {
                cols: "2",
                "align-self": "start",
                offset: _vm.showingHeightMetric ? 0 : 2
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    outlined: "",
                    "x-small": "",
                    id: "weightUnitsButton",
                    color: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeUnits(false)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.showingWeightMetric
                        ? _vm.$t("form.bmi.stoneAbbreviated") +
                            " / " +
                            _vm.$t("form.bmi.poundsAbbreviated")
                        : "kg"
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2", offset: "1" } }, [
            _c("p", { staticClass: "text-body-1 ml-1" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("form.bmi.definition") + " " + _vm.bmi) +
                  " "
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }